import{
  makeResponsive,
  placeTitle,
  createSpace,
  placeQuestion,
  placeComment,
  createAxes,
  writeHTMLText,
  drawPoint,
  setSize,
  labelIt,
  placeMarker,
  drawCircle,
  placeImage,
  placeShuffle,
  placeTest,
  drawArrow,
  drawAngle,
  drawSegment,
  placeBWhite,
  placeWhite,
  placeBCircles,
  placeCircles,
  placeChecks,
  placeCross,
  placeExclaim,
  hoverMe,
  placeLogo,
  drawMarker,
  toggle,
  toggleTF,
  placeFingers,
  placeAnswers,
  drawTriangle,
  placeRedo,
  placeStartOver,
  print,
  plotFunction,
  setWidth,
  drawLine,
  placePlay,
  placePause,
  writeMediumText,
  drawDash,
  drawIntersection,
  placeMiddleText,
  placeErase,
  drawSlider
} from '../Utils.js'
const Boxes = {
  box1: function () {
      var brd1=createSpace(-6, 10, -8, 8);
      brd1.options.axis.strokeWidth=0;
      brd1.options.layer['image']=17;
      brd1.options.layer['text']=19;
      brd1.options.layer['line']=16;
      brd1.options.layer['point']=17;
      brd1.options.layer['glider']=17;
      brd1.options.layer['angle']=2;
      brd1.options.board.minimizeReflow = 'none';
      brd1.options.point.showInfobox =false;
      brd1.options.line.highlight=false;
      brd1.options.image.highlight=false;
      brd1.options.text.highlight=false;
      brd1.options.circle.highlight=false;
      var ls = 1.0;
      var shft = 6.0;
      var a = 3.0;
      makeResponsive(brd1);
      placeLogo(brd1);
      placeTitle(brd1, 'Inscribed Angle Theorem', '');
      //brd1.create('text',[4., 10.25, 'Inscribed Angle Theorem'],{display:'internal', anchorX:'middle',cssStyle:'fontFamily:Oswald;fontWeight:bold', fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}, fixed:true});
      //Instruction
      placeShuffle(brd1, 'left');
      placeErase(brd1);
      placeMiddleText(brd1, 2, 6, 'Drag points A, B or C and observe angles a and b');
      placeMiddleText(brd1, 2, -4.5, 'The central angle (b) always remains twice of the inscribed angle (a).');
      //Circle
      //Origin
      //var orig=brd1.create('point', [0,0], {name:'O', size:3, face:'circle',fillColor:'black',strokeColor:'black',shadow:false, fixed:true});
      var orig =drawPoint(brd1, 0, 0);
      var rad = drawPoint(brd1, a, 0);
      rad.setAttribute({visible:false});
      var circ = drawCircle(brd1, orig, rad);
      circ.setAttribute({strokeColor:'black', fillOpacity:0.5});
      //Piston
      var pA= drawSlider(brd1, circ, -a, 0);
      labelIt(brd1,pA, 'A');
      var pB= drawSlider(brd1, circ, 2, 2.33);
      labelIt(brd1,pB, 'B');
      var pC=drawSlider(brd1, circ, 2, -2.33);
      labelIt(brd1,pC, 'C');
      //vert
      var AB=brd1.create('segment',[pA, pB],{strokeColor:'red', strokeWidth:3, fixed:true, visible:true});
      var AC=brd1.create('segment',[pA, pC],{strokeColor:'red', strokeWidth:3, fixed:true, visible:true});
      //
      var OB=brd1.create('segment',[orig, pB],{strokeColor:'blue', strokeWidth:3, fixed:true, visible:true});
      var OC=brd1.create('segment',[orig, pC],{strokeColor:'blue', strokeWidth:3, fixed:true, visible:true});
      var CAB =brd1.create('nonreflexangle', [pC, pA, pB], { radius:1, strokeWidth:2, name:'a', size:20, visible:true});
      var OAB =brd1.create('angle', [pC, orig, pB], { radius:1, strokeWidth:2, name:'b',size:20, visible:true});
      brd1.create('text', [5, 0.5, function() { return 'a (degree) ='+ JXG.toFixed(CAB.Value()*180/Math.PI, 2); }], {cssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}, fixed:true});
      brd1.create('text', [5, -0.5, function() { return 'b (degree) ='+ JXG.toFixed(OAB.Value()*180/Math.PI, 2); }],{cssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}, fixed:true});
      brd1.create('text', [5, -1.5, function() { return 'b/a ='+ JXG.toFixed(OAB.Value()/CAB.Value(), 1); }],{cssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}, fixed:true});
      brd1.unsuspendUpdate();
      },
    box2: function () {
    var brd2 = JXG.JSXbrd1.initBoard('jxgbox2',{boundingbox: [-4, 12, 12, -4],keepaspectratio:true, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
    brd2.suspendUpdate();
    var ls2 = 1.0;
    var shft2 = 6.0;
    var a2 = 3.0;
    //title
    var resize2 = function () {
    brd2.resizeContainer(brd2.containerObj.clientWidth, brd2.containerObj.clientHeight, true);
    brd2.fullUpdate();
    };
    window.addEventListener("resize", resize2);
    //glider1
    var title2= brd2.create('text',[4,10.25, 'Thales Theorem'],{display:'internal',anchorX:'middle', cssStyle:'fontFamily:Oswald;fontWeight:bold', fontSize:function(){return Math.round(32*brd2.canvasWidth/800.)}, fixed:true});
    //Instruction
    brd2.create('text',[4, 9., 'Points A, B or C can be moved on the circle.'],
  {display:'internal', anchorX:'middle', cssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}, fixed:true});
  brd2.create('text',[4, 8., 'Point D is diagonally opposite to point B.'],
{display:'internal', anchorX:'middle', cssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}, fixed:true});
brd2.create('text',[4, 7., 'Drag point C to point D to construct a diameter of the circle.'],
{display:'internal', anchorX:'middle', cssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}, fixed:true});

    //Circle
    var circ2=brd2.create('circle',[[0.0, 0.0],[a2, 0.0]],{strokeColor:'black', strokeWidth:4, fixed:true});
    //Origin
    var orig2=brd2.create('point', [0,0], {name:'O', size:3, face:'circle',fillColor:'black',strokeColor:'black',shadow:false, fixed:true});
    //Piston
    var pA2=brd2.create('glider',[-a2, 0.0, circ2],{name:'A',size:3, face:'square',fillColor:'white',strokeColor:'black',shadow:true, visible:true});
    //
    var pB2=brd2.create('glider',[2.00, 2.23, circ2],{name:'B',size:3, face:'square',fillColor:'white',strokeColor:'black',shadow:true});
    //
    var OB2=brd2.create('line',[orig2, pB2],{strokeColor:'blue', strokeWidth:0, fixed:true});
    //Diagonal Point
    var dp=brd2.create('intersection', [circ2, OB2, 1], {name:'D', size:3, face:'square'});
    //Third Point
    var pC2=brd2.create('glider',[2.00, -2.23, circ2],{name:'C',size:3, face:'square',fillColor:'white',strokeColor:'black',shadow:true, attractors:[dp],attractorDistance:0.2, snatchDistance:2});
    //
    //vert
    var AB2=brd2.create('segment',[pA2, pB2],{strokeColor:'red', strokeWidth:3, fixed:true, visible:true});
    var AC2=brd2.create('segment',[pA2, pC2],{strokeColor:'red', strokeWidth:3, fixed:true, visible:true});
    //
    var OC2=brd2.create('segment',[orig2, pC2],{strokeColor:'blue', strokeWidth:3, fixed:true, visible:true});
    var OB22=brd2.create('segment',[orig2, pB2],{strokeColor:'blue', strokeWidth:3, fixed:true, visible:true});
    var CAB2 =brd2.create('angle', [pC2, pA2, pB2], { radius:1, strokeWidth:2, name:'a', size:20, visible:true});
    var OAB2 =brd2.create('angle', [pC2, orig2, pB2], { radius:1, strokeWidth:2, name:'b',size:20, visible:true});
    brd2.create('text', [6, 1, function() { return 'a (degree) ='+ JXG.toFixed(CAB2.Value()*180/Math.PI, 1); }], {cssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}, fixed:true});
    brd2.create('text', [6, -1, function() { return 'b (degree) ='+ JXG.toFixed(OAB2.Value()*180/Math.PI, 1); }], {cssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}, fixed:true});
    brd2.unsuspendUpdate();
    },
}
export default Boxes;
