<template>
  <div>
    <h3 ref="ia">
      Inscribed Angle
    </h3>
    <p>
      In geometry, an inscribed angle is defined as the angle subtended at a point on the circle by two given points on the circumference of the circle.
      For example, in the figure shown below, angle marked by 'a' is the inscribed angle subtended by points B and C on point A.
    </p>
    <br>
    <v-layout justify-center>
      <v-img src="/assets/ia.png"
             max-height="350px"
             max-width="350px"
             width="400px"
      />
    </v-layout>
    <h3 ref="ca">
      Central Angle
    </h3>
    <p>
      A central angle is defined as the angle subtended at the center of the circle by two given points on the circumference of the circle.
      For example, in the figure shown below, angle marked by 'b' is the central angle subtended by points B and C on the center of the circle.
    </p>
    <br>
    <v-layout justify-center>
      <v-img src="/assets/ca.png"
             max-height="350px"
             max-width="350px"
             width="400px"
             contain
      />
    </v-layout>
    <h3 ref="iat">
      Statement of the Inscribed Angle Theorem
    </h3>
    <p>
      Inscribed angle theorem states that the measure of an inscribed angle (shown by 'a' in the figure below) subtended by any two points on the circle is
      half of the measure of the central angle (shown by 'b' in the figure below) subtended by those two points.
    </p>
    <br>
    <v-layout justify-center>
      <v-img src="/assets/iat.png"
             max-height="350px"
             max-width="350px"
             width="400px"
             contain
      />
    </v-layout>
    <h3 ref="playgraph">
      MagicGraph | Inscribed Angle Theorem
    </h3>
    <p>
      The purpose of the interactive illustration below is to help students understand the inscribed angle theorem.
      Points A, B and C are three movable points located on the circumference of the circle with center at O.
      The angle 'a' is the inscribed angle by arc BC at A while the angle 'b' is the central angle subtended by arc BC at the center of the circle. For any choice of A, B, and C, you will notice that the angle 'a' is always half the value of angle 'b'.
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'InscribedAngleTheorem',
  created: function () {
      this.$store.commit('navigation/resetState');
    // Store mutations
    let title = 'Inscribed Angle Theorem';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Inscribed Angle', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Central Angle', img:'/assets/number-2.svg', action: () => this.goto('ca')},
      {title: 'Theorem Statement', img:'/assets/number-3.svg', action: () => this.goto('iat')},
      {title: 'Graphical Interpretation',img:'/assets/touch.svg', action: () => this.goto('graphical')},
      {title: 'Special Case : Thales Theorem', img:'/assets/touch.svg', action: () => this.goto('thales')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  //  Boxes.box2();
  },
  metaInfo() {
  return{ title: 'Inscribed Angle Theorem',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Inscribed Angle Theorem'}
                ]
        }}
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
